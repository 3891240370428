import React, { useState } from 'react'
import RTGLink from '@shared/link'
import { objectOf, arrayOf, any, string, bool, array, shape } from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import '../../../assets/css/components/product/product-parts/product-description.sass'
import { hasIn } from 'lodash'
import { decodeTrademarkEntity } from '@helpers/string-helper'
import BunkieQuery from '@components/product/product-parts/product-description-queries/bunkie'
import TeakQuery from '@components/product/product-parts/product-description-queries/teak'
import TipQuery from '@components/product/product-parts/product-description-queries/tip'
import Box from '@material-ui/core/Box'

const StyledBoldBox = styled(Box)`
  font-weight: 600;
  text-transform: capitalize;
`

const ProductDescription = ({
  product,
  product: {
    addon_items,
    assembly_instructions,
    customer_assembly_required,
    description,
    dimension_image,
    dimensions,
    material,
    sku,
    swatch,
  },
  items_in_room,
  focused,
}) => {
  const [readMore, setReadMore] = useState(false)
  const hasBunkieAddon = addon_items
    ? addon_items.filter(addon => addon.addon_required).some(addon => addon.title.indexOf('Bunkie') > -1)
    : false

  return (
    <>
      {product && (
        <div
          className={classNames(' grid-x small-12 cell add-margin-top', {
            'medium-7': dimension_image,
          })}
        >
          {description && (
            <div
              className={classNames(' cell product-description card grid-x small-12', {
                'read-more-expander': readMore,
              })}
            >
              <div className={classNames(' small-12', { 'medium-7': dimension_image })}>
                <StyledBoldBox component="h2" m={1}>
                  product description
                </StyledBoldBox>
                <div>
                  <Box
                    component="p"
                    m={1}
                    dangerouslySetInnerHTML={{
                      __html: product?.description,
                    }}
                  />
                </div>
                {hasIn(product, 'addon_items') && hasBunkieAddon ? <BunkieQuery /> : null}
                {hasIn(product, 'tipping_restraint_included') && <TipQuery />}
                <div className="cell small-12 product-dimensions">
                  {dimensions && (
                    <div>
                      <StyledBoldBox m={1} component="span">
                        Dimensions
                      </StyledBoldBox>
                      {dimensions}
                    </div>
                  )}
                  {items_in_room &&
                    items_in_room.map(item => (
                      <div key={`items_in_room_${item.generic_name}`}>
                        {item.dimensions && item.generic_name && (
                          <div>
                            <StyledBoldBox component="span" m={1}>{`${item.generic_name} dimensions:`}</StyledBoldBox>
                            {item.dimensions}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="cell small-12">
                  <div className="product-sku">
                    <StyledBoldBox m={1} mt={2} component="span">
                      SKU:
                    </StyledBoldBox>
                    {sku && sku.toUpperCase()}
                  </div>
                </div>
                {customer_assembly_required && assembly_instructions && (
                  <div className="cell small-12">
                    <div className="assembly-instructions">
                      <br />
                      <p>
                        <RTGLink
                          data={{
                            target: '_blank',
                            url: assembly_instructions[0],
                            category: 'product description',
                            action: 'product assembly instructions click',
                            label: 'Assembly Instructions',
                          }}
                        >
                          Manufacturer's Assembly Instructions
                          <span className="hide508">PDF (Opens in new window)</span>
                        </RTGLink>
                      </p>
                      <p>Subject to change. Please consult the instructions included with your delivery.</p>
                    </div>
                  </div>
                )}

                {hasIn(product, 'material') && (material === 'teak' || material.includes('teak')) ? (
                  <div className="cell small-12">
                    <TeakQuery />
                  </div>
                ) : null}

                {swatch && (
                  <div className="fabric-swatches">
                    <button type="button">Request free fabric swatches &gt</button>
                  </div>
                )}
              </div>
              {dimension_image && (
                <div className="description-image small-12 medium-5 grid-y grid-margin-y">
                  <div className=" small-10">
                    <img src={dimension_image} alt="Dimensions" />
                  </div>
                </div>
              )}
              <div className="background-white-cover">
                <button
                  className={classNames('read-more-btn', {
                    focused,
                  })}
                  aria-label="read-more"
                  value="Read More"
                  gtm-category="pdp"
                  gtm-action="expand description"
                  gtm-label={sku}
                  onClick={() => setReadMore(!readMore)}
                  type="button"
                >
                  {readMore ? 'read less' : 'read more'}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

ProductDescription.propTypes = {
  focused: bool,
  items_in_room: array,
  product: objectOf(any), // left out of refactor for now
  addon_items: arrayOf(any),
  assembly_instructions: array,
  customer_assembly_required: bool,
  description: string,
  dimension_image: string,
  dimensions: string,
  sku: string,
  swatch: string,
  material: string,
}

export default ProductDescription
